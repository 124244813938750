<template>
  <!--begin::Charts Widget 1-->
  <div class="card strip-bg" :class="widgetClasses">
    <!--begin::Chart-->
    <apexchart
      type="bar"
      height="630"
      id="chart-issue"
      :options="options"
      :series="series"
    ></apexchart>
    <!--end::Chart-->
  </div>
  <!--end::Charts Widget 1-->
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import ApexCharts from "apexcharts";
import { useStore } from "vuex";

export default defineComponent({
  name: "Grafik-Issue",
  props: {
    widgetClasses: String,
    widgetData: Object,
  },
  setup(props) {
    const store = useStore();

    const data = computed(() => store.state.ResumeModule.issue);

    const issue_name = ref([]);
    const issue_count = ref([]);
    const form = ref({
      title: "",
      subtitle: "",
    });

    props.widgetData.map((item) => {
      issue_name.value.push(item.name);
      issue_count.value.push(item.count);
      form.value.title = item.title;
      form.value.subtitle = item.subtitle;
    });

    watch(data, () => {
      updateChart();
    });

    const options = {
      grid: {
        padding: {
          top: 20,
        },
      },
      chart: {
        id: "chart-issue",
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000000"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex];
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      xaxis: {
        categories: issue_name.value,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: form.value.title,
        align: "center",
        floating: true,
        margin: 20,
      },
      subtitle: {
        text: form.value.subtitle,
        align: "center",
      },
    };

    const series = [
      {
        name: "Jumlah",
        data: issue_count.value,
      },
    ];

    const updateChart = () => {
      const dummy = ref({
        name: [],
        data: [],
        title: "",
        subtitle: "",
      });

      if (data.value) {
        dummy.value.name = data.value.map((item) => {
          return item.name;
        });
        dummy.value.data = data.value.map((item) => {
          return item.count;
        });
        dummy.value.title = data.value[0].title;
        dummy.value.subtitle = data.value[0].subtitle;
      }

      const categories = Object.values(dummy.value.name);
      const nilai = Object.values(dummy.value.data);

      ApexCharts.exec("chart-issue", "updateOptions", {
        xaxis: {
          categories: categories,
        },
        chart: {
          height: 30 * nilai.length,
        },
        title: {
          text: dummy.value.title,
        },
        subtitle: {
          text: dummy.value.subtitle,
        },
      });
      ApexCharts.exec(
        "chart-issue",
        "updateSeries",
        [
          {
            data: nilai,
          },
        ],
        true
      );
    };

    return {
      options,
      series,
    };
  },
});
</script>
