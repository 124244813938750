<template>
  <!--begin::Basic info-->
  <div class="card border-radius-0" style="border-radius: 0;">
    <!--begin::Card header-->
    <div class="card-header border-radius-0 bg-default" style="border-radius: 0;">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <h3 class="fw-bolder m-0 text-white">Jawa Barat</h3>
        <div class="
            float-end
            d-flex
            align-items-right
            justify-content-between
            w-70
          ">
          <select name="" id="" class="form-select me-2" @input="getKabupaten" v-model="form.kabupaten">
            <option value="" selected>Semua Kabupaten</option>
            <option v-for="(item, i) in kabupaten" :key="i" :value="item.territory.id">
              {{ item.territory.name }}
            </option>
          </select>
          <select name="" id="" class="form-select me-2" @input="getKecamatan" v-model="form.kecamatan" style="display: none;">
            <option value="" selected>Kecamatan</option>
            <option v-for="(item, i) in kecamatan" :key="i" :value="item.territory.id">
              {{ item.territory.name }}
            </option>
          </select>
          <select name="" id="" class="form-select me-2" @input="getKelurahan" v-model="form.kelurahan" style="display: none;">
            <option value="" selected>Kelurahan</option>
            <template v-if="kelurahan">
              <option v-for="(item, i) in kelurahan" :key="i" :value="item.territory.id">
                {{ item.territory.name }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <template v-if="issue && issue.length">
      <ChartIssue :widget-data="issue" widget-classes="shadow" />
    </template>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import ChartIssue from "@/components/custom/ChartIssue.vue";

export default defineComponent({
  components: { ChartIssue },
  name: "Share Gifts",
  methods: {
    filterItems: function (items) {
      return items.filter(function (item) {
        if (
          item.territory.id == 284 ||
          item.territory.id == 285 ||
          item.territory.id == 288 ||
          item.territory.id == 289
        ) {
          return true;
        }
      });
    },
  },
  setup() {
    setCurrentPageTitle("Share Gifts");

    const store = useStore();

    store.commit("SET_ACTIVE_MENU", "issue");
    store.dispatch("getCountIssueResume", {});
    store.dispatch("AllTerritory", { type: 3 });

    const form = ref({
      kabupaten: "",
      kecamatan: "",
      kelurahan: "",
    });

    const activity = computed(() => store.state.ActivityDashboardModule.all);

    const issue = computed(() => store.state.ResumeModule.issue);

    const kabupaten = computed(() => store.state.TerritoryModule.kabupaten);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);

    const getKabupaten = (el) => {
      form.value.kecamatan = "";
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 4, parent_id: el.target.value });
        updateDataWilayah({
          kabupaten: el.target.value,
        });
      } else {
        form.value.kabupaten = "";
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 4, territories: [] });
      }
    };

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah({
          kecamatan: el.target.value,
        });
      } else {
        updateDataWilayah({
          kabupaten: form.value.kabupaten,
        });
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        updateDataWilayah({
          kelurahan: el.target.value,
        });
      } else {
        updateDataWilayah({
          kecamatan: form.value.kecamatan,
        });
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("getCountIssueResume", wilayah);
    };

    const updateTanpaWilayah = () => {
      store.dispatch("getCountIssueResume", {});
    };

    return {
      issue,
      activity,
      form,
      kabupaten,
      kecamatan,
      kelurahan,
      getKabupaten,
      getKecamatan,
      getKelurahan,
    };
  },
});
</script>